





































































































































































































































































.image-preview {
    width: 390px;

    img {
        width: 100%;
        aspect-ratio: 1.3;
        object-fit: cover;
    }
}
